/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import '../styles/crimsonrgamestheme.css';

import Header from "./header"
import Navbar from "./navBar"
import { Helmet } from "react-helmet";

const Layout = ({ children, pageInfo }) => {
  
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={data => (
        <>
          <Container fluid className="px-0 main">
            <Navbar pageInfo={pageInfo} />
            <Row noGutters>
              <Col>
                <Container fluid className="no_padding">
                  <main className="no_scroll_x">{children}</main>
                </Container>
              </Col>
            </Row>
          </Container>
          <Container fluid className="px-0">
            <Row noGutters>
              <Col className="footer-col">
                <footer>
                  <span>
                    © Aldo Pedro Rangel Montiel 2012 - {new Date().getFullYear()}.<br/> Built with
                  {` `}
                    <a href="https://www.gatsbyjs.org">Gatsby</a>
                  </span>
                </footer>
              </Col>
            </Row>
          </Container>
        </>
      )}
    />
  )
}

export default Layout
